class Logger {
  static LOG_LEVELS = {
    FATAL: 'FATAL',
    ERROR: 'ERROR',
    WARN: 'WARN',
    INFO: 'INFO',
    DEBUG: 'DEBUG'
  };
  static LOG_LEVELS_ORDER = { FATAL: 5, ERROR: 4, WARN: 3, INFO: 2, DEBUG: 1 };
  static SYSTEM_LOG_LEVEL =
    this.LOG_LEVELS_ORDER[process.env.REACT_APP_LOG_LEVEL] || this.LOG_LEVELS_ORDER.INFO;

  static debug(message, ...optionalParams) {
    this.log(this.LOG_LEVELS.DEBUG, message, ...optionalParams);
  }
  static info(message, ...optionalParams) {
    this.log(this.LOG_LEVELS.INFO, message, ...optionalParams);
  }
  static warn(message, ...optionalParams) {
    this.log(this.LOG_LEVELS.WARN, message, ...optionalParams);
  }
  static error(message, ...optionalParams) {
    this.log(this.LOG_LEVELS.ERROR, message, ...optionalParams);
  }
  static fatal(message, ...optionalParams) {
    this.log(this.LOG_LEVELS.FATAL, message, ...optionalParams);
  }

  static log(logLevel, message, ...optionalParams) {
    if (this.LOG_LEVELS_ORDER[logLevel] >= this.SYSTEM_LOG_LEVEL) {
      console.log(`${new Date().toISOString()} ${logLevel} -`, message, ...optionalParams);
    }
  }
}

export default Logger;
