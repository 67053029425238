const Permissions = {
  CHECKING_MODE: {
    AND: 'AND',
    OR: 'OR'
  },
  PAT_MGMT: {
    selfModuleName: 'PAT_MGMT',
    VIEW_BILL_HIST: 'PAT_MGMT.VIEW_BILL_HIST',
    NEW_PAT: 'PAT_MGMT.NEW_PAT',
    VIEW_PAT: 'PAT_MGMT.VIEW_PAT',
    EDIT_PAT: 'PAT_MGMT.EDIT_PAT',
    DEL_PAT: 'PAT_MGMT.DEL_PAT',
    PRT_PAT_LBL: 'PAT_MGMT.PRT_PAT_LBL',
    ADD_PAY_DOC: 'PAT_MGMT.ADD_PAY_DOC',
    VIEW_PAT_DOC: 'PAT_MGMT.VIEW_PAT_DOC',
    DL_PAT_DOC: 'PAT_MGMT.DL_PAT_DOC',
    DEL_PAT_DOC: 'PAT_MGMT.DEL_PAT_DOC',
    EDIT_PAT_DOC_NAME: 'PAT_MGMT.EDIT_PAT_DOC_NAME',
    VIEW_BK_HIST: 'PAT_MGMT.VIEW_BK_HIST'
  },
  DRUG_MGMT: {
    selfModuleName: 'DRUG_MGMT',
    EDIT_DRUG: 'DRUG_MGMT.EDIT_DRUG',
    VIEW_DRUG: 'DRUG_MGMT.VIEW_DRUG',
    VIEW_DRUG_BAT: 'DRUG_MGMT.VIEW_DRUG_BAT',
    NEW_DRUG: 'DRUG_MGMT.NEW_DRUG',
    NEW_DRUG_BAT: 'DRUG_MGMT.NEW_DRUG_BAT',
    EDIT_DRUG_BAT: 'DRUG_MGMT.EDIT_DRUG_BAT'
  },
  RMDR_MGMT: {
    selfModuleName: 'RMDR_MGMT',
    NEW_RMDR: 'RMDR_MGMT.NEW_RMDR',
    EDIT_PERS_RMDR: 'RMDR_MGMT.EDIT_PERS_RMDR',
    VIEW_RMDR: 'RMDR_MGMT.VIEW_RMDR',
    DEL_PERS_RMDR: 'RMDR_MGMT.DEL_PERS_RMDR',
    EDIT_RMDR: 'RMDR_MGMT.EDIT_RMDR',
    DEL_RMDR: 'RMDR_MGMT.DEL_RMDR',
    VIEW_PERS_RMDR: 'RMDR_MGMT.VIEW_PERS_RMDR'
  },
  CONS_MGMT: {
    selfModuleName: 'CONS_MGMT',
    VIEW_CONS_BAT: 'CONS_MGMT.VIEW_CONS_BAT',
    EDIT_CONS_BAT: 'CONS_MGMT.EDIT_CONS_BAT',
    EDIT_CONS: 'CONS_MGMT.EDIT_CONS',
    VIEW_CONS: 'CONS_MGMT.VIEW_CONS',
    NEW_CONS_BAT: 'CONS_MGMT.NEW_CONS_BAT',
    NEW_CONS: 'CONS_MGMT.NEW_CONS'
  },
  CONSULT_MGMT: {
    selfModuleName: 'CONSULT_MGMT',
    EDIT_CONSULT: 'CONSULT_MGMT.EDIT_CONSULT',
    EDIT_CONSULT_HIST: 'CONSULT_MGMT.EDIT_CONSULT_HIST',
    NEW_CONSULT: 'CONSULT_MGMT.NEW_CONSULT',
    VIEW_CONSULT_W_HIST: 'CONSULT_MGMT.VIEW_CONSULT_W_HIST',
    PRT_DRUG_LBL: 'CONSULT_MGMT.PRT_DRUG_LBL'
  },
  BK_MGMT: {
    selfModuleName: 'BK_MGMT',
    EDIT_BK: 'BK_MGMT.EDIT_BK',
    VIEW_PERS_BK_Q: 'BK_MGMT.VIEW_PERS_BK_Q',
    EDIT_PAT_ATTD: 'BK_MGMT.EDIT_PAT_ATTD',
    NEW_BK: 'BK_MGMT.NEW_BK',
    VIEW_BK_Q: 'BK_MGMT.VIEW_BK_Q',
    VIEW_BK_CAL: 'BK_MGMT.VIEW_BK_CAL',
    VIEW_PERS_BK_CAL: 'BK_MGMT.VIEW_PERS_BK_CAL',
    DEL_BK: 'BK_MGMT.DEL_BK'
  },
  RPT: {
    selfModuleName: 'RPT',
    DL_RPT: 'RPT.DL_RPT',
    GEN_RPT: 'RPT.GEN_RPT',
    VIEW_RPT: 'RPT.VIEW_RPT'
  },
  DRUGSET_MGMT: {
    selfModuleName: 'DRUGSET_MGMT',
    NEW_DRUGSET: 'DRUGSET_MGMT.NEW_DRUGSET',
    VIEW_DRUGSET: 'DRUGSET_MGMT.VIEW_DRUGSET',
    EDIT_DRUGSET: 'DRUGSET_MGMT.EDIT_DRUGSET',
    DEL_DRUGSET: 'DRUGSET_MGMT.DEL_DRUGSET'
  },
  BILL_MGMT: {
    selfModuleName: 'BILL_MGMT',
    VIEW_BILL: 'BILL_MGMT.VIEW_BILL',
    NEW_BILL: 'BILL_MGMT.NEW_BILL',
    EDIT_BILL: 'BILL_MGMT.EDIT_BILL',
    VIEW_INV: 'BILL_MGMT.VIEW_INV',
    DL_INV: 'BILL_MGMT.DL_INV',
    VIEW_PRESET_BILL_ITEM: 'BILL_MGMT.VIEW_PRESET_BILL_ITEM',
    NEW_PRESET_BILL_ITEM: 'BILL_MGMT.NEW_PRESET_BILL_ITEM',
    EDIT_PRESET_BILL_ITEM: 'BILL_MGMT.EDIT_PRESET_BILL_ITEM',
    DEL_PRESET_BILL_ITEM: 'BILL_MGMT.DEL_PRESET_BILL_ITEM'
  },
  USR_MGMT: {
    selfModuleName: 'USR_MGMT',
    NEW_USR: 'USR_MGMT.NEW_USR',
    VIEW_USR: 'USR_MGMT.VIEW_USR',
    EDIT_USR: 'USR_MGMT.EDIT_USR',
    DEL_USR: 'USR_MGMT.DEL_USR'
  }
};

export default Permissions;
