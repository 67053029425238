import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import Loading from 'components/shared-components/Loading';
import MobileNav from 'components/layout-components/MobileNav';
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import Footer from 'components/layout-components/Footer';
import AppViews from 'views/app-views';
import { Layout, Grid } from 'antd';
import moment from 'moment';
import navigationConfig from 'configs/NavigationConfig';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR
} from 'constants/ThemeConstant';
import utils from 'utils';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { setUserList, setUserPermissionsList, setUserNewReminder, setUserWithNoLoginList } from 'redux/actions/User';
import { setResourceList } from 'redux/actions/Resource';
import UserService from 'services/UserService';
import ResourceService from 'services/ResourceService';
import ReminderService from 'services/ReminderService';
import PermissionsConstant from '../../constants/Permissions';
import PaymentMethodService from '../../services/PaymentMethodService';
import { setPaymentMethodList } from '../../redux/actions/PaymentMethod';
import DrugLabelService from '../../services/DrugLabelService';
import DrugService from '../../services/DrugService';
import { setDrugLabelList } from '../../redux/actions/DrugLabel';
import { setDrugList } from '../../redux/actions/Drug';
import useCurrentUser from '../../hooks/useCurrentUser';
import ReminderConstant from '../../constants/ReminderConstant';
import logger from '../../logger';

const { Content } = Layout;
const { useBreakpoint } = Grid;

// export const AppLayout = ({ navCollapsed, navType, location, direction, setUserMap }) => {
export const AppLayout = ({
  navCollapsed,
  navType,
  location,
  direction,
  setUserList,
  setUserPermissionsList,
  setResourceList,
  userPermissionsList,
  setPaymentMethodList,
  setDrugLabelList,
  setDrugList,
  setUserNewReminder,
  setUserWithNoLoginList
}) => {
  const currentUser = useCurrentUser();
  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname);
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;

  const [isLoading, setIsLoading] = useState(false);
  const [isGetSelfRemindersLoading, setIsGetSelfRemindersLoading] = useState(false);

  useEffect(async () => {
    setIsLoading(true);
    await initUserResourceData();
    await initPaymentMethodData();
    await initDrugLabelData();
    await initDrugData();
    await getSelfReminders();
    setIsLoading(false);
  }, []);

  useEffect(async () => {
    logger.debug('#### AppLayout Location changed -', location);
    await getSelfReminders();
  }, [location]);

  const getSelfReminders = async () => {
    try {
      if (!isGetSelfRemindersLoading) {
        setIsGetSelfRemindersLoading(true);
        const inputParams = {
          page: 0,
          pageSize: 50,
          remindDateGte: moment(new Date()).format('YYYY-MM-DD'),
          remindDateLte: moment(new Date()).format('YYYY-MM-DD'),
          ownerType: 'USER',
          ownerId: currentUser.userId,
          status: ReminderConstant.STATUS.NEW
        };
        const remindersData = await ReminderService.getAll(inputParams);
        logger.debug('### remindersData:', remindersData);
        if (remindersData) {
          setUserNewReminder(remindersData.totalCount);
        }
        setIsGetSelfRemindersLoading(false);
      }
    } catch (error) {
      logger.error('### getSelfReminders error:', error);
    }
  };

  const initUserResourceData = async () => {
    const _userList = await UserService.getAllPages();
    if (_userList) {
      // logger.debug("_userList:", _userList);
      setUserList(_userList);
    }

    const _userWithNoLoginList = await UserService.getAllPages({includeLoginDisabled: true});
    if (_userWithNoLoginList) {
      // logger.debug("_userWithNoLoginList:", _userWithNoLoginList);
      setUserWithNoLoginList(_userWithNoLoginList);
    }

    logger.debug('userPermissionsList:', userPermissionsList);
    const _userPermissions = await UserService.permissions();
    if (_userPermissions) {
      logger.debug('_userPermissions:', _userPermissions);
      setUserPermissionsList(_userPermissions);
      // setUserPermissionsList([
      //   ..._userPermissions,
      //   // PermissionsConstant.PAT_MGMT.VIEW_PAT,
      //   // PermissionsConstant.DRUG_MGMT.VIEW_DRUG,
      //   // PermissionsConstant.BK_MGMT.VIEW_BK_CAL,
      //   // PermissionsConstant.BK_MGMT.NEW_BK,
      //   // PermissionsConstant.CONS_MGMT.VIEW_CONS,
      //   // PermissionsConstant.DRUG_MGMT.VIEW_DRUG,
      //   // PermissionsConstant.DRUG_MGMT.EDIT_DRUG_BAT,
      //   // PermissionsConstant.DRUGSET_MGMT.VIEW_DRUGSET,
      // ]);
    }

    const _resourceList = await ResourceService.getAllPages();
    if (_resourceList) {
      // logger.debug("_resourceList:", _resourceList);
      setResourceList(_resourceList);
    }
  };

  const initPaymentMethodData = async () => {
    const _paymentMethodList = await PaymentMethodService.getAll();
    if (_paymentMethodList) {
      logger.debug('_paymentMethodList:', _paymentMethodList);
      setPaymentMethodList(_paymentMethodList);
    }
  };

  const initDrugLabelData = async () => {
    const _drugLabelList = await DrugLabelService.getAllPages();
    if (_drugLabelList) {
      logger.debug('_drugLabelList:', _drugLabelList);
      setDrugLabelList(_drugLabelList);
    }
  };

  const initDrugData = async () => {
    const _drugList = await DrugService.getAllPages();
    if (_drugList) {
      logger.debug('_drugList:', _drugList);
      setDrugList(_drugList);
    }
  };

  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const { status } = useThemeSwitcher();

  if (status === 'loading' || isLoading) {
    return <Loading cover="page" />;
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() };
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() };
    }
    return { paddingLeft: getLayoutGutter() };
  };

  return (
    <Layout>
      {/*<HeaderNav isMobile={isMobile}/>*/}
      {/*{(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo}/> : null}*/}
      <TopNav routeInfo={currentRouteInfo} />
      <Layout className="app-container">
        {/*{(isNavSide && !isMobile) ? <SideNav routeInfo={currentRouteInfo}/> : null }*/}
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
            <Content>
              <AppViews />
            </Content>
          </div>
          {/*<Footer />*/}
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};

const mapStateToProps = ({ theme, user }) => {
  const { navCollapsed, navType, locale } = theme;
  const { userPermissionsList } = user;
  return { navCollapsed, navType, locale, userPermissionsList };
};

const mapDispatchToProps = {
  setUserList,
  setUserPermissionsList,
  setResourceList,
  setPaymentMethodList,
  setDrugLabelList,
  setDrugList,
  setUserNewReminder,
  setUserWithNoLoginList
  // setUserMap,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AppLayout));
