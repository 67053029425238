import authFetch from 'auth/AuthFetchInterceptor';
import { AUTH_TOKEN } from '../redux/constants/Auth';
import jwt_decode from 'jwt-decode';
import _ from 'lodash/core';

const Service = {};

Service.getAll = async function (params) {
  try {
    const resp = await authFetch({
      url: '/users',
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
      //   data: { grantType: "login", ...data },
      params
    });
    if (resp && resp.data) {
      return resp.data;
    }
  } catch (error) {}
  return null;
};

Service.getAllPages = async function (params) {
  let _allPagesData = [];
  try {
    let _currentPage = 0;
    let _totalPage = 1;
    while (_currentPage < _totalPage) {
      console.log('_currentPage:', _currentPage, ', _totalPage:', _totalPage);
      const _pageData = await this.getAll({
        pageSize: 20,
        page: _currentPage,
        ...params
      });
      if (_pageData) {
        if (_pageData.totalPage) {
          _totalPage = _pageData.totalPage;
        }
        if (_pageData.contents && _pageData.contents.length > 0) {
          _allPagesData = _allPagesData.concat(_pageData.contents);
        }
      }
      _currentPage += 1;
    }
  } catch (error) {}
  return _allPagesData;
};

Service.create = async function (data) {
  // console.log('data: ', data);
  try {
    const resp = await authFetch({
      url: '/users',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    });
    if (resp && resp.data) {
      return resp.data;
    }
  } catch (error) {}
  return null;
};

Service.getOne = async function (userId) {
  try {
    const resp = await authFetch({
      url: `/users/${userId}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (resp && resp.data) {
      return resp.data;
    }
  } catch (error) {}
  return null;
};

Service.update = async function (userId, data) {
  try {
    const resp = await authFetch({
      url: `/users/${userId}`,
      method: 'patch',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    });
    if (resp && resp.data) {
      return resp.data;
    }
  } catch (error) {}
  return null;
};

Service.inactivate = async function (userId) {
  try {
    const resp = await authFetch({
      url: `/users/${userId}/inactivate`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (_.isEmpty(resp)) {
      return true;
    }
  } catch (error) {}
  return null;
};

Service.permissions = async function () {
  try {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (token) {
      const decoded = jwt_decode(token);
      if (decoded && decoded.sub) {
        const resp = await authFetch({
          url: `/users/${decoded.sub}/permissions`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp && resp.data) {
          return resp.data;
        }
      }
    }
  } catch (error) {}
  return null;
};

export default Service;
