import {
  SET_USER_LIST,
  SET_USER_PERMISSIONS_LIST,
  SET_USER_NEW_REMINDER,
  SET_USER_WITH_NO_LOGIN_LIST
  // SET_USER_MAP,
} from '../constants/User';

export const setUserList = (userList) => {
  console.log('action setUserList');
  return {
    type: SET_USER_LIST,
    userList
  };
};

export const setUserPermissionsList = (userPermissionsList) => {
  console.log('action setUserPermissionsList');
  return {
    type: SET_USER_PERMISSIONS_LIST,
    userPermissionsList
  };
};

export const setUserNewReminder = (userNewReminder) => {
  console.log('action setUserNewReminder');
  return {
    type: SET_USER_NEW_REMINDER,
    userNewReminder
  };
};

export const setUserWithNoLoginList = (userWithNoLoginList) => {
  console.log('action userWithNoLoginList');
  return {
    type: SET_USER_WITH_NO_LOGIN_LIST,
    userWithNoLoginList
  };
};

// export const setUserMap = (users) => {
//     console.log("action setUserMap")
//     return {
//         type: SET_USER_MAP,
//         users,
//     };
// };
