import { SET_SEARCH_HISTORY_LIST } from '../constants/SearchHistory';

const initState = {
  searchHistoryList: {}
};

const searchHistory = (state = initState, action) => {
  const _searchHistoryList = state.searchHistoryList;
  switch (action.type) {
    case SET_SEARCH_HISTORY_LIST:
      _searchHistoryList[action.pageName] = action.searchHistoryObject;
      return {
        ...state,
        searchHistoryList: _searchHistoryList
      };
    default:
      return state;
  }
};
export default searchHistory;
