import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import User from './User';
import Resource from './Resource';
import PaymentMethod from './PaymentMethod';
import DrugLabel from './DrugLabel';
import Drug from './Drug';
import SearchHistory from './SearchHistory';

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  user: User,
  resource: Resource,
  paymentMethod: PaymentMethod,
  drugLabel: DrugLabel,
  drug: Drug,
  searchHistory: SearchHistory
});

export default reducers;
